var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(_vm.profile && _vm.profileDataProject)?_c('div',{staticClass:"d-flex flex-row client-profile"},[_c('div',{staticClass:"flex-row-auto offcanvas-mobile w-300px w-xl-350px",attrs:{"id":"kt_profile_aside"}},[_c('div',{staticClass:"card card-custom card-stretch"},[_c('div',{staticClass:"card-body pt-4"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('span',{staticClass:"font-weight-bolder font-size-h6 text-dark-75"},[_vm._v(_vm._s(_vm.profile.first_name + " " + _vm.profile.last_name || "-"))]),(_vm.profile.client_project && false)?_c('div',{staticClass:"text-muted font-size-h4 text-dark-75"},[_vm._v(" "+_vm._s(_vm.profile.client_project.name)+" ")]):_vm._e(),(_vm.getCampaign)?_c('b-badge',{staticClass:"mr-1 mt-1 d-block",attrs:{"variant":"info"}},[_vm._v(_vm._s(_vm.getCampaign))]):_vm._e()],1)]),(
              _vm.profileDataProject.client &&
              _vm.profileDataProject.client.projects_list.length > 1
            )?_c('div',{staticClass:"d-flex mt-1"},[_c('v-select',{attrs:{"items":_vm.projectsList,"item-text":"project_name","item-value":"project_id","dense":"","hide-details":"","outlined":""},on:{"change":_vm.selectProject},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('div',{staticClass:"px-5 mb-4"},[_c('v-text-field',{staticClass:"mt-0 pt-0 text-h6 w-100",attrs:{"placeholder":"Search","hide-details":""},model:{value:(_vm.projectsListSearch),callback:function ($$v) {_vm.projectsListSearch=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"projectsListSearch"}})],1)]},proxy:true}],null,false,1759321862),model:{value:(_vm.selectedProject),callback:function ($$v) {_vm.selectedProject=$$v},expression:"selectedProject"}})],1):_vm._e(),_c('div',{staticClass:"py-9"},[_c('div',{staticClass:"d-flex align-items-start mb-2"},[_c('span',{staticClass:"font-weight-bold mr-2"},[_vm._v("Email:")]),_c('span',{staticClass:"text-dark-50 justify-data"},[_vm._v(_vm._s(_vm.profile.email || "-"))])]),_c('div',{staticClass:"d-flex align-items-start mb-2"},[_c('span',{staticClass:"font-weight-bold mr-2"},[_vm._v("Mobile No:")]),_c('span',{staticClass:"text-dark-50 justify-data"},[_vm._v(_vm._s(_vm.profile.phone || "-"))])]),_c('div',{staticClass:"d-flex align-items-start mb-2"},[_c('span',{staticClass:"font-weight-bold mr-2"},[_vm._v("Meeting Date:")]),(
                  _vm.profileDataProject && _vm.profileDataProject.meeting_start_time
                )?_c('span',{staticClass:"text-dark-50 justify-data"},[_vm._v(_vm._s(_vm.submissionDate(_vm.profileDataProject.meeting_start_time) + " GMT"))]):_c('span',{staticClass:"text-dark-50 justify-data"},[_vm._v(_vm._s("-"))])]),_c('div',{staticClass:"d-flex align-items-start mb-2"},[_c('span',{staticClass:"font-weight-bold mr-2"},[_vm._v("Meeting Link:")]),_c('span',{staticClass:"text-dark-50 justify-data"},[(_vm.profileDataProject && _vm.profileDataProject.meeting_link)?_c('a',{attrs:{"href":_vm.profileDataProject.meeting_link,"target":"_blank"}},[_vm._v("Link")]):_c('span',{staticClass:"text-dark-50"},[_vm._v("-")])])]),_c('div',{staticClass:"d-flex align-items-start mb-2"},[_c('span',{staticClass:"font-weight-bold mr-2"},[_vm._v("Squad Request Date:")]),(_vm.profileDataProject.submission_date)?_c('span',{staticClass:"text-dark-50 justify-data"},[_vm._v(_vm._s(_vm.submissionDate(_vm.profileDataProject.submission_date)))]):_c('span',{staticClass:"text-dark-50 justify-data"},[_vm._v(_vm._s("-"))])]),_c('div',{staticClass:"d-flex align-items-start mb-2"},[_c('span',{staticClass:"font-weight-bold mr-2"},[_vm._v("Deal Owner :")]),(
                  _vm.profileDataProject && _vm.profileDataProject.deal_owner_email
                )?_c('span',{staticClass:"text-dark-50 justify-data"},[_vm._v(_vm._s(_vm.profileDataProject.deal_owner_email))]):_c('span',{staticClass:"text-dark-50 justify-data"},[_vm._v(_vm._s("-"))])]),_c('div',{staticClass:"d-flex align-items-start mb-2"},[_c('span',{staticClass:"font-weight-bold mr-2"},[_vm._v("Rec. Consultant :")]),(
                  _vm.profileDataProject &&
                  _vm.profileDataProject.account_manager_email
                )?_c('span',{staticClass:"text-dark-50 justify-data"},[_vm._v(_vm._s(_vm.profileDataProject.account_manager_email))]):_c('span',{staticClass:"text-dark-50 justify-data"},[_vm._v(_vm._s("-"))])]),_c('div',{staticClass:"d-flex align-items-center mb-2"},[_c('span',{staticClass:"font-weight-bold mr-2"},[_vm._v("Clickup URL:")]),(
                  _vm.profileDataProject && _vm.profileDataProject.clickup_task_url
                )?_c('a',{attrs:{"href":_vm.profileDataProject.clickup_task_url,"target":"_blank"}},[_c('img',{staticClass:"d-flex align-items-start",staticStyle:{"height":"2.5rem","width":"2.5rem"},attrs:{"src":require("@/assets/images/clickup.png"),"alt":"clickup"}})]):_c('span',{staticClass:"text-dark-50 justify-data"},[_vm._v(_vm._s("-"))])]),_c('div',{staticClass:"d-flex align-items-center mb-2"},[_c('span',{staticClass:"font-weight-bold mr-2"},[_vm._v("Renewal Type:")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('v-switch',{staticStyle:{"height":"30px"},attrs:{"value":'auto',"label":`${
                    _vm.profileDataProject.renewal_type == 'auto'
                      ? 'Auto'
                      : 'Manual'
                  }`,"inset":"","ripple":false},on:{"change":function($event){return _vm.changeRenewalType($event)}},model:{value:(_vm.profileDataProject.renewal_type),callback:function ($$v) {_vm.$set(_vm.profileDataProject, "renewal_type", $$v)},expression:"profileDataProject.renewal_type"}})],1)]),(
                _vm.profileDataProject &&
                _vm.profileDataProject.client?.hasOwnProperty('online_payments')
              )?_c('div',{staticClass:"d-flex align-items-center mb-2"},[_c('span',{staticClass:"font-weight-bold mr-2"},[_vm._v("Pay through Noon:")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('v-switch',{staticStyle:{"height":"30px"},attrs:{"inset":"","ripple":false},on:{"change":function($event){return _vm.changeOnlinePayment($event)}},model:{value:(_vm.profileDataProject.client.online_payments),callback:function ($$v) {_vm.$set(_vm.profileDataProject.client, "online_payments", $$v)},expression:"profileDataProject.client.online_payments"}})],1)]):_vm._e(),(
                _vm.profileDataProject &&
                _vm.profileDataProject.hasOwnProperty('interview_allowed')
              )?_c('div',{staticClass:"d-flex align-items-center mb-2"},[_c('span',{staticClass:"font-weight-bold mr-2 w-50"},[_vm._v("Request an interview with Matched Talents:")]),_c('div',{staticClass:"d-flex align-items-center"},[_c('v-switch',{staticStyle:{"height":"30px"},attrs:{"inset":"","ripple":false},on:{"change":function($event){return _vm.changeRequestInterview($event)}},model:{value:(_vm.profileDataProject.interview_allowed),callback:function ($$v) {_vm.$set(_vm.profileDataProject, "interview_allowed", $$v)},expression:"profileDataProject.interview_allowed"}})],1)]):_vm._e()]),_c('div',{staticClass:"navi navi-bold navi-hover navi-active navi-link-rounded",attrs:{"role":"tablist"}},[_c('div',{staticClass:"navi-item mb-2"},[_c('a',{ref:"clientRequest",staticClass:"navi-link py-4 active",staticStyle:{"cursor":"pointer"},attrs:{"data-tab":"1","data-toggle":"tab","role":"tab","aria-selected":"false"},on:{"click":_vm.setActiveTab}},[_c('span',{staticClass:"navi-icon"},[_c('span',{staticClass:"svg-icon"},[_c('i',{staticClass:"menu-icon far fa-user"})])]),_c('span',{staticClass:"navi-text font-size-lg"},[_vm._v("Client Request")])])]),_c('div',{staticClass:"navi-item mb-2"},[_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
                  _vm.profile.client_project && _vm.profile.projects_list.length
                    ? ''
                    : 'Add project details to be able to add stakeholders'
                ),expression:"\n                  profile.client_project && profile.projects_list.length\n                    ? ''\n                    : 'Add project details to be able to add stakeholders'\n                ",modifiers:{"hover":true,"right":true}}],ref:"Stakeholders",staticClass:"navi-link py-4",staticStyle:{"cursor":"pointer"},attrs:{"data-tab":_vm.profile.client_project && _vm.profile.projects_list.length
                    ? 0
                    : null,"data-toggle":"tab","role":"tab","aria-selected":"false"},on:{"click":(e) => {
                    _vm.profile.client_project && _vm.profile.projects_list.length
                      ? _vm.setActiveTab(e)
                      : false;
                  }}},[_c('span',{staticClass:"navi-icon"},[_c('span',{staticClass:"svg-icon"},[_c('i',{staticClass:"menu-icon flaticon-mail"})])]),_c('span',{staticClass:"navi-text font-size-lg"},[_vm._v("Stakeholders")])])]),_c('div',{staticClass:"navi-item mb-2"},[_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
                  _vm.profile.client_project && _vm.profile.projects_list.length
                    ? ''
                    : 'Add project details to be able to see nominations'
                ),expression:"\n                  profile.client_project && profile.projects_list.length\n                    ? ''\n                    : 'Add project details to be able to see nominations'\n                ",modifiers:{"hover":true,"right":true}}],ref:"nominations",staticClass:"navi-link py-4",staticStyle:{"cursor":"pointer"},attrs:{"data-tab":_vm.profile.client_project && _vm.profile.projects_list.length
                    ? 4
                    : null,"data-toggle":"tab","role":"tab","aria-selected":"false"},on:{"click":(e) => {
                    _vm.profile.client_project && _vm.profile.projects_list.length
                      ? _vm.setActiveTab(e)
                      : false;
                  }}},[_c('span',{staticClass:"navi-icon"},[_c('span',{staticClass:"svg-icon"},[_c('i',{staticClass:"menu-icon flaticon-layers"})])]),_c('span',{staticClass:"navi-text font-size-lg"},[_vm._v("Nominations")])])]),_c('div',{staticClass:"navi-item mb-2"},[_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
                  _vm.profile.client_project && _vm.profile.projects_list.length
                    ? ''
                    : 'Add project details to be able to see selected talents'
                ),expression:"\n                  profile.client_project && profile.projects_list.length\n                    ? ''\n                    : 'Add project details to be able to see selected talents'\n                ",modifiers:{"hover":true,"right":true}}],ref:"selectedTalents",staticClass:"navi-link py-4",staticStyle:{"cursor":"pointer"},attrs:{"data-tab":_vm.profile.client_project && _vm.profile.projects_list.length
                    ? 5
                    : null,"data-toggle":"tab","role":"tab","aria-selected":"false"},on:{"click":(e) => {
                    _vm.profile.client_project && _vm.profile.projects_list.length
                      ? _vm.setActiveTab(e)
                      : false;
                  }}},[_c('span',{staticClass:"navi-icon"},[_c('span',{staticClass:"svg-icon"},[_c('i',{staticClass:"menu-icon flaticon-squares-1"})])]),_c('span',{staticClass:"navi-text font-size-lg"},[_vm._v("Selected Talents")])])]),_c('div',{staticClass:"navi-item mb-2"},[_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
                  _vm.$route.params.project_id
                    ? _vm.profileDataProject &&
                      [
                        ..._vm.profileDataProject.technologies,
                        ..._vm.profileDataProject.others_technologies,
                      ].length &&
                      _vm.profileDataProject.hiring_type
                      ? ''
                      : 'Please select technologies & utilizations to be able to notify talents'
                    : _vm.profile.client_project &&
                      [
                        ..._vm.profile.client_project.technologies,
                        ..._vm.profile.client_project.others_technologies,
                      ].length > 0 &&
                      _vm.profile.client_project.hiring_type
                    ? ''
                    : 'Please select technologies & utilizations to be able to notify talents'
                ),expression:"\n                  $route.params.project_id\n                    ? profileDataProject &&\n                      [\n                        ...profileDataProject.technologies,\n                        ...profileDataProject.others_technologies,\n                      ].length &&\n                      profileDataProject.hiring_type\n                      ? ''\n                      : 'Please select technologies & utilizations to be able to notify talents'\n                    : profile.client_project &&\n                      [\n                        ...profile.client_project.technologies,\n                        ...profile.client_project.others_technologies,\n                      ].length > 0 &&\n                      profile.client_project.hiring_type\n                    ? ''\n                    : 'Please select technologies & utilizations to be able to notify talents'\n                ",modifiers:{"hover":true,"right":true}}],staticClass:"navi-link py-4",staticStyle:{"cursor":"pointer"},attrs:{"data-tab":_vm.$route.params.project_id
                    ? _vm.profileDataProject &&
                      [
                        ..._vm.profileDataProject.technologies,
                        ..._vm.profileDataProject.others_technologies,
                      ].length &&
                      _vm.profileDataProject.hiring_type
                      ? 2
                      : null
                    : _vm.profile.client_project &&
                      [
                        ..._vm.profile.client_project.technologies,
                        ..._vm.profile.client_project.others_technologies,
                      ].length &&
                      _vm.profile.client_project.hiring_type
                    ? 2
                    : null,"data-toggle":"tab","role":"tab","aria-selected":"false"},on:{"click":(e) => {
                    _vm.$route.params.project_id
                      ? _vm.profileDataProject &&
                        [
                          ..._vm.profileDataProject.technologies,
                          ..._vm.profileDataProject.others_technologies,
                        ].length &&
                        _vm.profileDataProject.hiring_type
                        ? _vm.setActiveTab(e)
                        : false
                      : _vm.profile.client_project &&
                        [
                          ..._vm.profile.client_project.technologies,
                          ..._vm.profile.client_project.others_technologies,
                        ].length &&
                        _vm.profile.client_project.hiring_type
                      ? _vm.setActiveTab(e)
                      : false;
                  }}},[_c('span',{staticClass:"navi-icon"},[_c('span',{staticClass:"svg-icon"},[_c('i',{staticClass:"menu-icon flaticon-email"})])]),_c('span',{staticClass:"navi-text font-size-lg"},[_vm._v("Notify Talent")])])]),_c('div',{staticClass:"navi-item mb-2"},[_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(
                  _vm.$route.params.project_id
                    ? _vm.profileDataProject &&
                      [
                        ..._vm.profileDataProject.technologies,
                        ..._vm.profileDataProject.others_technologies,
                      ].length &&
                      _vm.profileDataProject.hiring_type
                      ? ''
                      : 'Please select technologies & utilizations to be able to notify talents'
                    : _vm.profile.client_project &&
                      [
                        ..._vm.profile.client_project.technologies,
                        ..._vm.profile.client_project.others_technologies,
                      ].length > 0 &&
                      _vm.profile.client_project.hiring_type
                    ? ''
                    : 'Please select technologies & utilizations to be able to notify talents'
                ),expression:"\n                  $route.params.project_id\n                    ? profileDataProject &&\n                      [\n                        ...profileDataProject.technologies,\n                        ...profileDataProject.others_technologies,\n                      ].length &&\n                      profileDataProject.hiring_type\n                      ? ''\n                      : 'Please select technologies & utilizations to be able to notify talents'\n                    : profile.client_project &&\n                      [\n                        ...profile.client_project.technologies,\n                        ...profile.client_project.others_technologies,\n                      ].length > 0 &&\n                      profile.client_project.hiring_type\n                    ? ''\n                    : 'Please select technologies & utilizations to be able to notify talents'\n                ",modifiers:{"hover":true,"right":true}}],staticClass:"navi-link py-4",staticStyle:{"cursor":"pointer"},attrs:{"data-tab":_vm.$route.params.project_id
                    ? _vm.profileDataProject &&
                      [
                        ..._vm.profileDataProject.technologies,
                        ..._vm.profileDataProject.others_technologies,
                      ].length &&
                      _vm.profileDataProject.hiring_type
                      ? 3
                      : null
                    : _vm.profile.client_project &&
                      [
                        ..._vm.profile.client_project.technologies,
                        ..._vm.profile.client_project.others_technologies,
                      ].length &&
                      _vm.profile.client_project.hiring_type
                    ? 3
                    : null,"data-toggle":"tab","role":"tab","aria-selected":"false"},on:{"click":(e) => {
                    _vm.$route.params.project_id
                      ? _vm.profileDataProject &&
                        [
                          ..._vm.profileDataProject.technologies,
                          ..._vm.profileDataProject.others_technologies,
                        ].length &&
                        _vm.profileDataProject.hiring_type
                        ? _vm.setActiveTab(e)
                        : false
                      : _vm.profile.client_project &&
                        [
                          ..._vm.profile.client_project.technologies,
                          ..._vm.profile.client_project.others_technologies,
                        ].length &&
                        _vm.profile.client_project.hiring_type
                      ? _vm.setActiveTab(e)
                      : false;
                  }}},[_c('span',{staticClass:"navi-icon"},[_c('span',{staticClass:"svg-icon"},[_c('i',{staticClass:"menu-icon fa fa-users"})])]),_c('span',{staticClass:"navi-text font-size-lg"},[_vm._v("Interested List")])])])])])])]),_c('div',{staticClass:"flex-row-fluid ml-lg-8"},[_c('b-tabs',{staticClass:"hide-tabs"},[_c('b-tab',{attrs:{"active":_vm.tabIndex == 0}},[(_vm.tabIndex == 0)?_c('Stakeholders',{attrs:{"profile":_vm.profile},on:{"update":function($event){_vm.$route.params.project_id
                ? _vm.getProfileDataProjectWithOutLoading(
                    _vm.$route.params.project_id
                  )
                : _vm.getProfileDataWithOutLoading(_vm.$route.params.id)}}}):_vm._e()],1),_c('b-tab',{attrs:{"active":_vm.tabIndex == 1}},[(_vm.tabIndex == 1)?_c('ClientBasicInfo',{ref:"ClientBasicInfo",attrs:{"profile":_vm.profile},on:{"update":function($event){_vm.$route.params.project_id
                ? _vm.getProfileDataProjectWithOutLoading(
                    _vm.$route.params.project_id
                  )
                : _vm.redirectToProjectProfile()}}}):_vm._e()],1),_c('b-tab',{attrs:{"active":_vm.tabIndex == 4}},[(_vm.tabIndex == 4)?_c('nominations'):_vm._e()],1),_c('b-tab',{attrs:{"active":_vm.tabIndex == 2}},[(_vm.tabIndex == 2)?_c('NotifyTalants',{attrs:{"profile":_vm.profile},on:{"backToProfile":function($event){return _vm.$refs.clientRequest.click()}}}):_vm._e()],1),_c('b-tab',{attrs:{"active":_vm.tabIndex == 3}},[(_vm.tabIndex == 3)?_c('InterestedList',{attrs:{"profile":_vm.profile}}):_vm._e()],1),_c('b-tab',{attrs:{"active":_vm.tabIndex == 5}},[(_vm.tabIndex == 5)?_c('selected-talents',{attrs:{"selectedProject":_vm.selectedProject}}):_vm._e()],1)],1)],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }